<template>
	<div style="max-width: 900px" class="mx-auto">
		<div class="tarifa_resultado_wrapper">
			<!-- <div class="tarifa_resultado">
				<div class="texts">
					<h1 class="w-100 text-center h1 tarifa">3,95€</h1>
					<h4 class="w-100 text-center h4 tarifa">/mes</h4>
				</div>
			</div> -->
			<v-row style="height: 100%" no-gutters>
				<v-col
					cols="12" sm="6"
					align-self="center"
					style="height: 100%; display: flex; align-items: center; opacity: 0.8"
				>
					<v-sheet color="secondary" height="80%" width="100%">
						<div class="texts">
							<h1 class="w-100 text-center h1 tarifa">ENERGÍA VERDE A PRECIO DE COSTE</h1>
							<h4 class="w-100 text-center h4 tarifa">
								* de gestión y sin permanencia
							</h4>
						</div>
						<div class="btn-asistente ml-auto mr-sm-n16 mr-auto">*3,95€</div>
					</v-sheet>
				</v-col>
			</v-row>
		</div>

		<div id="grid-precios" class="mt-4">
			<div style="grid-area: tarifa" class="cabecera">Tarifa Contratada</div>
			<div style="grid-area: feeEnergia" class="cabecera text-no-wrap">
				FEE del Término de Energía (cts/kWh)
			</div>
			<div
				:style="`grid-area: PEnergia; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P
			</div>
			<div style="grid-area: terminoPotencia" class="cabecera text-no-wrap">
				Término de Potencia (€/kW y día)
			</div>
			<div
				:style="`grid-area: p1; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P1
			</div>
			<div
				:style="`grid-area: p2; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P2
			</div>
			<div
				:style="`grid-area: p3; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P3
			</div>
			<div
				:style="`grid-area: p4; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P4
			</div>
			<div
				:style="`grid-area: p5; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P5
			</div>
			<div
				:style="`grid-area: p6; color: ${accesibleColor}`"
				class="cabecera--periodo"
			>
				P6
			</div>

			<div style="grid-area: tarifaContratada" class="cabecera">2.0TD</div>

			<div class="dato">0**</div>
			<div class="dato">0.084035</div>
			<div class="dato">0.003902</div>
			<div class="dato"></div>
			<div class="dato"></div>
			<div class="dato"></div>
			<div class="dato"></div>

			<div class="dato">0**</div>
			<div class="dato">0.106881*</div>
			<div class="dato">0.004963*</div>
			<div class="dato"></div>
			<div class="dato"></div>
			<div class="dato"></div>
			<div class="dato"></div>

			<!-- <div style="grid-area: feeImp;" class="dato">2.289*</div> -->
		</div>
		<div class="d-flex flex-column mt-3">
			<small
				>* Precios con impuestos incluidos: IVA (21%) o impuestos indirectos
				correspondientes IGIC o IPSI según normativa aplicable e impuestos
				especiales Impuesto Eléctrico (5,1127%).</small
			>
			<small
				>** Al precio base se le añadirán los Costes de Gestión de 3.95
				euros/mes, aplicando con posterioridad los impuestos
				correspondientes</small
			>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		accesibleColor() {
			let hex = this.$vuetify.theme.themes.light.primary;
			const [red, green, blue] = hex
				.replace(
					/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
					(m, r, g, b) => "#" + r + r + g + g + b + b
				)
				.substring(1)
				.match(/.{2}/g)
				.map((x) => parseInt(x, 16));
			if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) return "#000000";
			else return "#ffffff";
		},
	},
};
</script>

<style scoped lang="scss">
.tarifa_resultado_wrapper {
	min-height: 500px;
	display: grid;
	// aspect-ratio: 16/9;
	/* background: #f3f3f3; */
	// display: flex;
	background-image: url("/imagenes/fondo_altaweb.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.tarifa_resultado {
	width: 100%;
	height: 100%;
	margin: 20px;
	background: #00000032;
	display: flex;
	align-items: center;
	justify-content: center;
}
	.texts {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 20px;
	}
	.h1 {
		font-size: 2em;
		color: white;
		font-family: "silka_bold";
	}
	.h4 {
		color: white;
		font-family: "silka_bold";
	}

#grid-precios {
	display: grid;
	grid-template-areas:
		"tarifa feeEnergia terminoPotencia terminoPotencia terminoPotencia terminoPotencia terminoPotencia terminoPotencia"
		"tarifa PEnergia p1 p2 p3 p4 p5 p6"
		"tarifaContratada fee . . . . . ."
		"tarifaContratada feeImp . . . . . .";
	gap: 2px;
	overflow-x: auto;

	& .cabecera {
		padding: 10px;
		background-color: var(--v-primary-base);
		color: white;
		text-align: center;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		&--periodo {
			padding: 5px 10px;
			background-color: var(--v-secondary-base);

			text-align: center;
		}
	}

	& .dato {
		padding: 5px;
		background-color: #f3f3f3;
		text-align: center;
	}
}

.btn-asistente {
	/* background: var(--v-primary-base); */
	background: linear-gradient(
		to right,
		#FBC002 0%,
		var(--v-primary-base) 50%,
		var(--v-secondary-base) 100%
	);
	aspect-ratio: 1;
	border-radius: 50%;
	border: 2px solid white;
	margin-top: 10px;
	color: white;
	max-width: 100px;
	max-height: 100px;
	aspect-ratio: 1;
	transition: box-shadow .2s;
	outline: none;
	font-size: 1.5rem;
	width: unset;
	display: grid;
	justify-content: center;
	align-content: center;
	/* grid-template-rows: 2fr 1fr; */
	align-items: center;
	z-index: 10;
	opacity: 1;
}
</style>